<template>
	<div class="app-container">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="礼包名称" @input='page.current=1'></el-input>
			</div>
			<div class="filter-item">
				<label class="label">礼包状态: </label>
				<el-select v-model="giftState" placeholder="请选择" @change="changeGiftState">
					<el-option v-for="item in giftOptions" :key="item.value" :label="item.lable" :value="item.value"> </el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addrulePackge'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleTableEdit(1)">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading='loading'>
				<el-table-column prop="Name" label="礼包名称"></el-table-column>
				<el-table-column label="发放时间">
					<template slot-scope='scope'>
						<span v-if='scope.row.IsPermanentActivity'>永久有效</span>
						<span v-else>{{scope.row.StartTime}}至{{scope.row.EndTime}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="ActivityStateShow" label="状态"></el-table-column>
				<el-table-column prop="AllCount" label="礼包总数"></el-table-column>
				<el-table-column prop="SendCount" label="已发放数量"></el-table-column>
				<el-table-column prop="SendMemberCount" label="已领取人数"></el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="220px">
					<template slot-scope='scope'>
						<div v-if="scope.row.ActivityState!=2">
							<button-permissions :datas="'EditrulePackge'">
								<div class="table-button" @click="handleTableEdit(2,scope.row)">编辑</div>
							</button-permissions>
							
							<button-permissions :datas="'StoprulePackge'">
								<div class="table-button" @click="handleTableStop(scope.row.Id)">停用</div>
							</button-permissions>
							
							<button-permissions :datas="'CopyrulePackge'">
								<div class="table-button" @click="handleTableEdit(3,scope.row)">复制</div>
							</button-permissions>
							
						</div>

						<div v-else>
							<button-permissions :datas="'CheckrulePackge'">
								<div class="table-button" @click="handleTableEdit(4,scope.row)">查看</div>
							</button-permissions>
							<button-permissions :datas="'CopyrulePackge'">
								<div class="table-button" @click="handleTableEdit(3,scope.row)">复制</div>
							</button-permissions>
							<button-permissions :datas="'deleterulePackge'">
								<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.Id)">删除</div>
							</button-permissions>
							
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import apiList from "@/api/other";
	import buttonPermissions from "@/components/buttonPermissions";
	export default {
		components: {
			buttonPermissions,
		},
		data() {
			return {
				loading: false,
				searchKey: "",
				giftState: null,
				giftOptions: [{
						lable: "全部",
						value: null,
					},
					{
						lable: "未开始",
						value: 0,
					},
					{
						lable: "使用中",
						value: 1,
					},
					{
						lable: "已停用",
						value: 2,
					},
				],
				tableData: [{}],
				page: {
					total: 0,
					current: 1,
					size: 20,
        }, 
			};
		},
		created() {
			this.getList()
		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						Keywords: this.searchKey,
						ActivityState: this.giftState,
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
					};
					let result = await apiList.activityTopUpGiftList(data);
					this.tableData = result.Result.Results || [];
					this.page.total = result.Result.Total;
				} catch (error) {
					this.loading = false;
				} finally {
					this.loading = false;
				}
			},
			changeGiftState() {
				this.page.current = 1
			},
			handleFilter() {
				this.getList();
			},
			handleTableEdit(type, val) {
				//type，1:添加；2：编辑；3：复制；4：查看
        //ActivityState 0:未开始；1：使用中；2：已停用
				this.$router.push({
					path: "/Customer/storedPackageEdit",
					query: {
						editId: val ? val.Id : null,
						editType: type,
						editActivityState: (type == 2 || type == 4) ? val.ActivityState : 0
					},
				});
			},

			// 删除
			handleTableDelete(id) {
				this.$confirm("是否确认删除这个礼包？删除后不可恢复", "提示", {
					confirmButtonText: "确认删除",
					cancelButtonText: "关闭",
					type: "warning",
				}).then(async () => {
					let result = await apiList.activityTopUpGiftDelete({
						Id: id,
					});
					if (result.IsSuccess) {
						this.getList();
						this.$message({
							showClose: true,
							type: "success",
							message: "删除成功!",
						});
					}
				}).catch(() => {
					this.$message({
						showClose: true,
						type: "info",
						message: "已取消删除",
					});
				}).finally(() => {});
			},
			// 停用
			handleTableStop(id) {
				this.$confirm(
					"礼包停用后，你将不可再对这个礼包进行编辑，是否确认停用？礼包停用不影响已发放的礼包",
					"提示", {
						confirmButtonText: "确认停用",
						cancelButtonText: "关闭",
						type: "warning",
					}
				).then(async () => {
					let result = await apiList.activityTopUpGiftOpen({
						Id: id,
					});
					if (result.IsSuccess) {
						this.getList();
						this.$message({
							showClose: true,
							type: "success",
							message: "停用成功!",
						});
					}
				}).catch(() => {
					this.$message({
						showClose: true,
						type: "info",
						message: "已取消停用",
					});
				}).finally(() => {});
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
		},
	};
</script>

<style lang="less" scoped>
	.app-container {
		::v-deep .el-input {
			width: 220px;
		}

		background-color: #fff;
	}

	.table-button {
		color: #409eff;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog {
		width: 100%;

		::v-deep .el-dialog__header {
			border-bottom: 1px solid #ddd;
		}

		.content {
			padding: 0 30px;
			display: flex;
			flex-direction: row;

			.right {
				margin-left: 20px;
			}

			.text {
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}

		.button {
			margin: 0 auto;
			width: 200px;
		}
	}

	.data-dialog {
		.value {
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}

		.item {
			color: #666;
			margin-top: 10px;
		}
	}

	.el-pagination {
		::v-deep .el-input {
			width: 100px;
		}
	}

	.dialog-footer {
		text-align: center;
		margin-top: 20px;
	}

	.item-tooltip {
		width: 255px;
	}

	.el-tooltip__popper[x-placement^="right"] .popper__arrow {
		border-right-color: #1ab394;
	}

	.el-tooltip__popper[x-placement^="right"] .popper__arrow:after {
		border-right-color: #1ab394;
	}

	.pop-item-tooltip {
		background: #1ab394 !important;
	}
</style>
